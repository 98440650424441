import { useCallback, useContext, useMemo } from "react";

import { AppContext } from "../../../App";
import { Chat, Container, Span, Table } from "../../../c";
import { formatMoney } from "../../../f";
import { DropdownEditMenu } from "./DropdownEditMenu";

import SITUATIONS from "./contents/label_situations.json";
import { getColumns } from "../TravelRecords/useCases/getColumns";
import { transformToRow } from "../TravelRecords/useCases/transformToRow";

const travelRecordsColumns = [
  "ticketId",
  "productGropup",
  "scheduleOn",
  "vehicle",
  "taxDocument",
  "operation",
  "service",
  "mainAmount",
  "additionalAmount",
];

export const FaturamentoCard = ({
  faturamento,
  setObservacao,
  onFetchRecords,
  setViewDocument,
}) => {
  const App = useContext(AppContext);
  const lang = { ...App.lang.global };
  const icons = App.icons;

  const formatDate = useCallback(
    (date) => {
      return App.moment(date).format(lang.formatoData);
    },
    [App, lang.formatoData]
  );

  const columns = useMemo(() => {
    const travelColumns = getColumns({ user: App.user });

    return travelRecordsColumns
      .map((column) => {
        return travelColumns.find(
          ([, travelColumn]) => column === travelColumn
        );
      })
      .filter((column) => column);
  }, [App.user]);

  const travelRecords = useMemo(() => {
    return faturamento.portarias.map((travelRecord) => {
      return transformToRow({
        // rows
        columns,
        lang: App.lang.freight_controllership.travel_records,
        onFetchRecords,
        user: App.user,
        onToggle: () => { },
        record: travelRecord,
      });
    });
  }, [
    App.lang.freight_controllership.travel_records,
    App.user,
    columns,
    faturamento.portarias,
    onFetchRecords,
  ]);

  const discountValue = faturamento.VL_SDO - faturamento.VL_DSC;

  const formatFaturamentoNumber = (nrDcp) => {
    return `#${nrDcp}`;
  };

  return (
    <div className="invoicing-card">
      <div className="header" style={{ margin: "-10px -10px 0" }}>
        <div className="f g2 w100 header-content">
          <div className="f g4 inner-container">
            <div className="info-block">
              <div className="info-section">
                <span className="title">RF</span>
                <span className="featured-content">{faturamento.ID_UOCC}</span>
              </div>
              <div className="info-section">
                <span className="title">Referência</span>
                <span className="content">
                  {formatFaturamentoNumber(faturamento.NR_DCP)}
                </span>
              </div>
            </div>

            <div className="info-block">
              <div className="info-section">
                <span className="title">Transportador</span>
                <span className="featured-content w100 white-space">
                  {faturamento.NM_TRANSP}
                </span>
              </div>
              <div className="info-section">
                <div className="f">
                  <div className="info-section">
                    <span className="title margin-right">Viagens</span>
                    <span className="content">{travelRecords.length}</span>
                  </div>
                  <div className="info-section">
                    <span className="title">Desconto</span>
                    <span className="content">
                      {formatMoney(faturamento.VL_DSC, 2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="info-block">
              <div className="info-section">
                <span className="title white-space">Valor</span>
                <span className="featured-content white-space">
                  {formatMoney(discountValue, 2)}
                </span>
              </div>

              <div className="info-section">
                <span className="title">Valor total</span>
                <span className="content">
                  {formatMoney(faturamento.VL_DCP, 2)}
                </span>
              </div>
            </div>

            <div className="info-block">
              <div className="info-section">
                <span className="title">Vencimento</span>
                <span className="featured-content">
                  {formatDate(faturamento.DT_VCT)}
                </span>
              </div>
              <div className="info-section">
                <span className="title">Criado em</span>
                <span className="content">
                  {formatDate(faturamento.DT_DCP)}
                </span>
              </div>
            </div>
          </div>
          <div
            className="status"
            style={{
              backgroundColor: SITUATIONS[faturamento.FAT_STS].color,
              position: "absolute",
              right: "0",
              top: "0",
              padding: "0",
              margin: "0",
            }}
          >
            <Span
              style={{ padding: "0" }}
              value={
                <span className="f g1 center-v center-h">
                  {SITUATIONS[faturamento.FAT_STS].label ?? "Sem status"}
                  {faturamento.CD_IDC === "2" ? (
                    <icons.MdEmail size={24} />
                  ) : faturamento.CD_IDC === "4" ? (
                    <icons.MdMarkEmailRead size={24} />
                  ) : null}
                  <DropdownEditMenu
                    faturamento={faturamento}
                    setObservacao={setObservacao}
                    onFinish={onFetchRecords}
                    control={<icons.BsThreeDotsVertical size={18} />}
                    setViewDocument={setViewDocument}
                  />
                </span>
              }
              className="center"
            />
          </div>
        </div>
      </div>
      <div className="infos"></div>
      <Container>
        <Table
          isSub
          hasSub="SUB"
          data={travelRecords}
          columns={columns}
          fixedPerPage={10}
          className={`h100 ${travelRecords.length > 10 ? "paginated" : ""}`}
        />
      </Container>
      <Chat
        user={App.user.id_usr}
        msg={faturamento.OBSS}
        height={-1}
        className={faturamento.OBSS.length > 0 ? 'observations' : 'observations hidden'}
      />
    </div>
  );
};
