import moment from "moment";

export const ColumnsCache = {
  getCacheKey: (tableId) => `users::columns::${tableId}`,

  getData: (tableId) => {
    const cacheKey = ColumnsCache.getCacheKey(tableId);

    const cachedData = localStorage.getItem(cacheKey);

    if (!cachedData) return [null, 'loaded'];

    const { data, expiresIn, status = 'loaded' } = JSON.parse(cachedData);

    if (status === 'loading') return [data, status];

    if (!expiresIn || !moment().isBefore(moment(expiresIn))) return [null, 'expired'];

    return [data, status];
  },

  setData: (tableId, data, status) => {
    const cacheKey = ColumnsCache.getCacheKey(tableId);
    const expiresIn = moment().add(5, 'minutes').toISOString()

    localStorage.setItem(cacheKey, JSON.stringify({ expiresIn, data, status }));
  },

  clearData: (tableId) => {
    const cacheKey = ColumnsCache.getCacheKey(tableId);

    localStorage.removeItem(cacheKey);
  }
};
