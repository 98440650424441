import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AppContext } from '../../App'
import CryptoJS from 'crypto-js'

import { toDateType } from '../../f'
import { Checkbox, IconButton, Input } from '..'
import { NothingHere } from '../NothingHere'

import { extractCacheFromColumns } from './useCases/extractCacheFromColumns'
import { initiateColumnsData } from './useCases/initiateColumnsData'
import { processColumns } from './useCases/processColumns'
import { saveColumns } from './useCases/saveColumns'

const perPageOptions = [10, 25, 50, 100, 250]

export const Table = React.memo(({
  allowOrder = true,
  className: initialClassName = '',
  columns,
  data = [],
  disableLine = null,
  emptyMsg = null,
  filter = {},
  fixedPerPage = null,
  forceFullHeight = null,
  hasSub = null,
  isSub = false,
  loading = false,
  noFooter = null,
  noHeader = null,
  onLoadSubData = null,
  pagination = 'client-side', // client-side / no-pagination
  perPage: initialPerPage = null,
  rowClick = null,
  rowEnabledField = null,
  search = '',
  selectColumns = true,
  selectedRow = () => false,
  showFilters = false,
  style = {},
  subOpenedDefault = null,
}) => {
  const App = useContext(AppContext);
  const icons = App.icons;
  const lang = App.lang.global;
  const columnsRef = useRef(null);

  const [subs, setSubs] = useState(Array(data.length).fill(subOpenedDefault));
  const [filters, setFilters] = useState(filter);
  const [perPage, setPerPage] = useState(App.prefs?.tableNumber ?? initialPerPage ?? 25);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState({ asc: true, field: null });
  const [columnsEditing, setColumnsEditing] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [columnsCache, setColumnsCache] = useState('');
  const [loadingTable, setLoadingTable] = useState(!isSub);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [dropdownClass, setDropdownClass] = useState('');

  const tableRef = useRef(null)

  const colunas = useMemo(() => processColumns(data, columns), [columns, data])

  const officialPerPage = useMemo(() => {
    if (fixedPerPage === null) return perPage

    const tempPerPage = (fixedPerPage ?? 0)

    if (5 <= tempPerPage && tempPerPage <= [...perPageOptions].pop()) {
      return fixedPerPage
    }

    return perPage
  }, [fixedPerPage, perPage])

  const filtered = useMemo(() => {
    return (data || [])
      .map((item) => {
        return Object.entries(item).reduce((memo, [key, value]) => {
          return { ...memo, [key]: typeof value === 'string' ? value.trim() : value }
        }, {})
      })
      .filter((item) => { // Filtros específicos
        const mostrarItem = colunas.every((coluna) => {
          const { [coluna.dataField]: dataField } = item
          const { [coluna.field]: fieldFilter = '' } = filters || {}

          const possuiDataField = (dataField ?? '')
            .toString()
            .toUpperCase()
            .includes(fieldFilter.toUpperCase())

          return !coluna.sortable || dataField === null || possuiDataField
        })

        if (showFilters && !mostrarItem) return false // verifica se a coluna pode ser mostrada pelo filtro

        const searcheable = Object.values(item).some((value) => {
          if (!['string', 'number'].includes(typeof value)) return false

          const valueUpcased = value.toString().toUpperCase()

          return search
            .toString()
            .toUpperCase()
            .split(' ')
            .every((term) => valueUpcased.includes(term))
        })

        return search === '' || searcheable // verifica se a coluna pode ser pesquisável
      })
      .sort((a, b) => {
        return allowOrder && !!orderBy.field
          ? (
            orderBy?.typeof === 'number'
              ? orderBy.asc
                ? a[orderBy.dataField] - b[orderBy.dataField]
                : b[orderBy.dataField] - a[orderBy.dataField]
              : orderBy?.typeof === 'string'
                ? orderBy.asc
                  ? a[orderBy.dataField]?.localeCompare(b[orderBy.dataField])
                  : b[orderBy.dataField]?.localeCompare(a[orderBy.dataField])
                : orderBy?.typeof === 'date'
                  ? orderBy.asc
                    ? toDateType(a[orderBy.dataField]) - toDateType(b[orderBy.dataField])
                    : toDateType(b[orderBy.dataField]) - toDateType(a[orderBy.dataField])
                  : 0
          ) : 0
      })
  }, [allowOrder, colunas, data, filters, orderBy.asc, orderBy.dataField, orderBy.field, orderBy?.typeof, search, showFilters])

  const columnsShow = useMemo(
    () => columnsData.filter(({ IN_INSERT }) => isSub || IN_INSERT === 'T'),
    [columnsData, isSub],
  )

  const totalPages = useMemo(() => {
    return Math.max(
      Math.ceil((filtered?.length ?? 0) / officialPerPage),
      0
    )
  }, [filtered?.length, officialPerPage])

  const dataShow = useMemo(() => {
    if (pagination !== 'client-side') return filtered

    const inicioRegistro = (page - 1) * officialPerPage
    const fimRegistro = (page) * officialPerPage

    return filtered.slice(inicioRegistro, fimRegistro)
  }, [officialPerPage, filtered, page, pagination])

  const exibeTamanhoPaginacao = useMemo(() => {
    if (pagination === 'no-pagination') return false

    if (pagination === 'client-side') {
      if (fixedPerPage === officialPerPage) return totalPages > 1

      return filtered.length > perPageOptions[0]
    }

    return true // pagination === 'server-side'
  }, [filtered.length, fixedPerPage, officialPerPage, pagination, totalPages])

  const exibePaginacao = useMemo(() => {
    if (pagination === 'no-pagination') return false

    if (pagination === 'client-side') {
      if (fixedPerPage === officialPerPage) return totalPages > 1

      return (filtered.length > perPageOptions[0]) && !!totalPages
    }

    return totalPages > 1 // pagination === 'server-side'
  }, [filtered.length, fixedPerPage, officialPerPage, pagination, totalPages])

  const tableId = useMemo(() => {
    const tableString = colunas.map(({ field }) => field).join('');

    return `table-${CryptoJS.MD5(JSON.stringify(tableString)).toString()}`;
  }, [colunas])

  const loadSubData = useCallback(async (rowIndex, rowData) => {
    //Inverte a visualização do detalhe para o indice do registro
    subs[rowIndex] = !subs[rowIndex];
    setSubs([...subs]);

    //Está abrindo os detalhes para o registro
    if (!!subs[rowIndex]) {
      //Se tem API, e se é a primeira vez que abre os detalhes do indíce do registro
      if (!!data[rowIndex].hasSub) return;

      data[rowIndex].hasSub = true;

      if (typeof onLoadSubData !== 'function') return

      try {
        setLoadingDetails(rowIndex);
        await onLoadSubData(rowIndex, rowData);
      } finally {
        setLoadingDetails(false);
      }
    }
  }, [data, onLoadSubData, subs])

  const renderPagination = useCallback(() => {
    if (!exibePaginacao) return <div></div>

    const handleChangePagination = (newPage) => {
      return () => {
        setPage((preState) => {
          if (newPage < 1 || totalPages < newPage) return preState

          return newPage
        })
      }
    }

    const handleSelectPagination = (event) => {
      handleChangePagination(Number(event.target.value))()
    }

    return (
      <div className='pagination f center g2'>
        <IconButton
          size={32}
          icon={icons.MdFirstPage}
          onClick={handleChangePagination(1)}
          disabled={page <= 1}
        />
        <IconButton
          size={32}
          icon={icons.MdNavigateBefore}
          onClick={handleChangePagination(page - 1)}
          disabled={page <= 1}
        />

        {lang.pagina}
        <select onChange={handleSelectPagination} value={page}>
          {Array.from({ length: totalPages }).map((_, index) => (
            <option key={index} value={index + 1}>{index + 1}</option>
          ))}
        </select>
        {`${lang.de} ${totalPages}`}

        <IconButton
          size={32}
          icon={icons.MdNavigateNext}
          onClick={handleChangePagination(page + 1)}
          disabled={page >= totalPages}
        />
        <IconButton
          size={32}
          icon={icons.MdLastPage}
          onClick={handleChangePagination(totalPages)}
          disabled={page >= totalPages}
        />
      </div>
    )
  }, [exibePaginacao, icons.MdFirstPage, icons.MdLastPage, icons.MdNavigateBefore, icons.MdNavigateNext, lang.de, lang.pagina, page, totalPages])

  const renderPerPageSelect = useCallback(() => {
    if (!exibeTamanhoPaginacao) return <></>

    const options = fixedPerPage === officialPerPage
      ? [
        <option key="option" value={officialPerPage}>{officialPerPage}</option>
      ]
      : perPageOptions.map((item) => (
        <option key={item} value={item}>{item}</option>
      ))

    const handleChangePerPage = (event) => {
      const tableNumber = event.target.value
      const newPerPage = Number(tableNumber)

      setPerPage(newPerPage, App.setPrefs({ tableNumber }));
      setPage(1)
    }

    return (
      <div className='per-page f g2 center-v'>
        {lang.itens_por_pagina}:
        {fixedPerPage === officialPerPage ? (
          <IconButton
            disabled
            value={officialPerPage}
            onChange={handleChangePerPage}
          >
            {options}
          </IconButton>
        ) : (
          <select
            value={officialPerPage}
            onChange={handleChangePerPage}
          >
            {options}
          </select>
        )}
      </div>
    )

  }, [App, exibeTamanhoPaginacao, fixedPerPage, lang.itens_por_pagina, officialPerPage])

  const handleCheckDisplayColumn = useCallback((column, index) => {
    return ({ target: { checked } }) => {
      const IN_INSERT = checked ? "T" : "F"

      const newColumnsData = [
        ...columnsData.slice(0, index), // os itens do array antes do item alterado
        { ...column, IN_INSERT }, // item alterado
        ...columnsData.slice(index + 1), // itens do array após o item alterado
      ]

      const totalColumnsDisplayed = newColumnsData
        .filter((columnData) => columnData.IN_INSERT === 'T' && columnData.label)
        .length

      if (totalColumnsDisplayed === 0) return // pelo menos uma coluna deve ser exibida

      setColumnsData(newColumnsData)
    }
  }, [columnsData])

  useEffect(() => {
    (async () => {
      setLoadingTable(true)

      try {
        const cache = `${extractCacheFromColumns(colunas)}-${tableId}`

        if (cache === columnsCache) return

        setColumnsCache(cache)

        const newColumnsData = await initiateColumnsData({
          api: App.api,
          columns: colunas,
          tableId
        })

        setColumnsData(newColumnsData)
      } finally {
        setLoadingTable(false)
      }
    })()
  }, [App.api, columnsCache, colunas, tableId])

  useEffect(() => {
    setPage(Math.max(Math.min(page, totalPages), 1))
  }, [page, totalPages])

  //VD-12851 - Samuel - 04/06/2024
  useEffect(() => {
    const handleClickOutside = async (event) => {
      // O usuário está editando as colunas, então não faz nada aqui
      if (!columnsRef.current || columnsRef.current.contains(event.target)) return;

      if (columnsEditing) setColumnsEditing(false)

      if (columnsData.length === 0) return

      if (columnsData.every(({ IN_INSERT, OLD_IN_INSERT }) => IN_INSERT === OLD_IN_INSERT)) return

      setLoadingTable(true);

      // Houve alterações, então salva as colunas
      const prefs = columnsData.reduce(
        (hiddenColumns, { IN_INSERT, field }) => {
          if (IN_INSERT === "F") return [...hiddenColumns, field]

          return hiddenColumns
        },
        []
      )

      try {
        await saveColumns({ api: App.api, tableId, prefs })
      } finally {
        setLoadingTable(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [App.api, columnsData, columnsEditing, tableId])

  useEffect(() => {
    if (columnsEditing && tableRef.current) {
      const rowCount = tableRef.current.querySelectorAll('tbody tr').length;
      setDropdownClass(rowCount <= 7 ? 'dropdown-menu-below' : 'dropdown-menu-above');
    }
  }, [columnsEditing]);

  if (loadingTable) return <App.LoadingComponent />

  return (
    <div className={`table-default f f-column g2 w100 ${initialClassName}`.trim()}>
      <div style={{ overflowX: 'auto', ...forceFullHeight && { height: 'calc(100vh - ' + forceFullHeight + 'px)' } }}>
        <table cellSpacing={0} style={style} ref={tableRef}>
          <thead>
            {!noHeader &&
              <tr>
                {!!hasSub && <th key='forsub' width='36' className='force-fit'></th>}
                {columnsShow.map((c, ci) => (
                  <th key={c.key ?? ci} className={c.sortable ? 'sortable' : ''}
                    onClick={() => allowOrder && c.sortable && setOrderBy({ ...c, asc: orderBy.field === c.field ? !orderBy.asc : orderBy.asc })}
                  >
                    <div style={c.style} className={c.class}>{/* Precisa dessa DIV pq o TH fica distorcido com flex */}
                      {c.label}
                      {allowOrder && orderBy.field === c.field
                        && <span className='table-order-buttons'>
                          <icons.MdArrowDropDown size={24} className={orderBy.asc && 'selected'} />
                          <icons.MdArrowDropUp size={24} className={!orderBy.asc && 'selected'} />
                        </span>
                      }
                    </div>
                  </th>
                ))}
              </tr>
            }
            {!!showFilters && <tr className='table-filters'>
              {!!hasSub && <th key='forsub'></th>}
              {columnsShow.map((col, ci) => (
                <th key={col.key ?? ci}>
                  {col.sortable &&
                    <Input clearable value={filters[col.field]} onChange={e =>
                      setFilters({ ...filters, [col.field]: e.target.value })
                    } placeholder={col.label} />
                  }
                </th>
              ))}
            </tr>}
          </thead>

          <tbody>
            {loading
              ? (
                <tr>
                  <td colSpan={columnsShow.length + (!!hasSub ? 1 : 0)}>
                    <App.LoadingComponent />
                  </td>
                </tr>
              ) : (
                <>
                  {dataShow?.map((r, ri) => (
                    <Fragment key={r.key || ri}>
                      <tr className={[
                        selectedRow(r) ? 'selected' : '',
                        rowClick ? 'clickable' : '',
                        disableLine && r?.[disableLine] ? 'disabled-line' : '',
                        !!rowEnabledField && r?.[rowEnabledField] === false ? 'disabled' : ''
                      ].join(' ')}
                        onClick={() => { if (rowClick) rowClick(r) }}
                      >
                        {!!hasSub &&
                          <td key={ri + 'forsub'} width='36'>
                            {(!(!!rowEnabledField && r?.[rowEnabledField] === false) && r?.[hasSub] &&
                              <IconButton onClick={() => { loadSubData(ri, r); }}>
                                {!!subs[ri] ? <icons.MdExpandLess size={16} /> : <icons.MdExpandMore size={16} />}
                              </IconButton>
                            )}
                          </td>}
                        {columnsShow.map((c, ci) =>
                          <td key={ci}>
                            <div style={c.style} className={[c.class].join(' ')}>{r?.[c?.field]}</div>
                          </td>)}
                      </tr>

                      {!!hasSub && subs[ri] && !(!!rowEnabledField && r?.[rowEnabledField] === false) && (
                        loadingDetails === ri ? (
                          <tr>
                            <td colSpan={columnsShow.length + (!!hasSub ? 1 : 0)}>
                              <App.LoadingComponent />
                            </td>
                          </tr>
                        ) : (
                          <tr className='table-sub' key={ri + 'sub'}>
                            <td colSpan={columnsShow.length + 1}>{r?.[hasSub]}</td>
                          </tr>
                        )
                      )}
                    </Fragment>
                  ))}
                  {!dataShow?.length &&
                    <tr>
                      <td colSpan={columnsShow.length + (!!hasSub ? 1 : 0)}>
                        <NothingHere message={emptyMsg} />
                      </td>
                    </tr>}
                </>
              )}
          </tbody>
        </table>
      </div>

      {!noHeader && !noFooter &&
        <div className='table-footer'>
          {!isSub &&
            //VD-12851 - Samuel - 23/04/2024
            <div className={`dropdown-menu ${dropdownClass}`}>
              <IconButton
                disabled={!selectColumns}
                onClick={() => {
                  if (selectColumns) setColumnsEditing((prevState) => !prevState)
                }}
              >
                <icons.MdEditNote />
              </IconButton>
              {columnsEditing && (<ul ref={columnsRef}>
                {columnsData.map((col, ci) =>
                  !!col.label && !("ACTIONS").includes(col.field.toUpperCase()) && (
                    <li
                      key={`${col.label}-${col.field}-${col.IN_INSERT}-${col.OLD_IN_INSERT}`}
                      style={{ cursor: 'auto' }}
                    >
                      <Checkbox
                        checked={col.IN_INSERT === "T"}
                        label={col?.label}
                        className='w100'
                        onChange={handleCheckDisplayColumn(col, ci)}
                      />
                    </li>
                  ))}
              </ul>)}
            </div>
          }

          {renderPagination()}

          {renderPerPageSelect()}
        </div>
      }
    </div>
  );
})

export default Table
