import { useCallback, useContext, useMemo, useState } from "react";

import { AppContext } from "../../../App";
import { Frame, PreviewDocumentModal } from "../../../c";
import { CardFilters } from "../../../c/CardFilters";
import { NothingHere } from "../../../c/NothingHere";
import { getCardFilters } from "../../../useCases/getCardFilters";

import cardFiltersConfig from './contents/card_filters_config.json'
import { filterRecords } from "./useCases/filterRecords";
import { FaturamentoCard } from './FaturamentoCard'
import { FaturamentoObs } from './FaturamentoObs'

export const InvoiceRecords = ({ loading, records = [], onFetchRecords }) => {
  const App = useContext(AppContext)

  const [observacao, setObservacao] = useState(null)
  const [selectedCardFilter, setCardFilter] = useState({})
  const [, setPdf] = useState(null)
  const [viewDocument, setViewDocument] = useState(false)

  const lang = useMemo(() => {
    const { global, produtos, menu, faturamento } = App.lang
    return ({ ...global, ...produtos, ...menu, ...faturamento })
  }, [App.lang]);

  const handleFilterCard = useCallback((newFilter) => {
    setCardFilter(newFilter)
  }, [])

  const cardFilters = useMemo(() => {
    return getCardFilters(cardFiltersConfig, {
      data: records,
      lang: lang.card_filters ?? lang,
      selectedFilter: selectedCardFilter,
      onFilter: handleFilterCard
    })
  }, [handleFilterCard, lang, records, selectedCardFilter])

  const cards = useMemo(() => {
    return filterRecords({ records, filter: selectedCardFilter })
  }, [records, selectedCardFilter])

  return (
    <>
      <Frame className="invoice-records card-filters">
        <CardFilters visible filters={cardFilters} />
      </Frame>

      <Frame
        flex
        className="invoice-records records"
        loading={loading}
        title={lang.registro_fatura}
      >
        {cards.map((card, index) => (
          <FaturamentoCard
            faturamento={card}
            key={index}
            setObservacao={setObservacao}
            onFetchRecords={onFetchRecords}
            setPdf={setPdf}
            setViewDocument={setViewDocument}
          />
        ))}

        {cards.length === 0 && (<NothingHere />)}

        {observacao && (
          <FaturamentoObs
            faturamento={observacao}
            op={observacao.op}
            onFinish={onFetchRecords}
            onClose={() => setObservacao(null)}
          />
        )}

        {!!viewDocument && (
          <PreviewDocumentModal previewDocumentModal={viewDocument}
            onClose={() => {
              onFetchRecords();
              setViewDocument(false);
            }}
          />
        )}
      </Frame>
    </>
  )
}
