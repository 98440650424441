import { useState, useEffect, useContext } from "react"
import { AppContext } from '../../App'
import * as c from '../../c'
import * as p from '..'
import * as f from '../../f'

export default function GerencialFracionada() {
  const App = useContext(AppContext)
  let lang = { ...App.lang.global, ...App.lang.dashCargaDescarga, ...App.lang.GerencialFracionada }
  let icons = App.icons

  const
    [dtIni, setDtIni] = useState(App.moment().toDate()),
    [dtFim, setDtFim] = useState(App.moment().toDate()),
    [data, setData] = useState({}),
    [listas, setListas] = useState(null),
    [filtro, setFiltro] = useState(null),
    [cancelAgendado, setCancelAgendado] = useState({}),
    [loading, setLoading] = useState(null),
    [trpGraphData, setTrpGraphData] = useState([]),
    [trpGraphActiveIndex, setTrpGraphActiveIndex] = useState(0)

  const graphPieColors = ['brown', 'green', 'blue', 'orange', 'darkkhaki', 'cornflowerblue', 'red', 'blueviolet', 'burlywood', 'gray']

  function getListas() {
    dtIni && dtFim && App.api('Fracionada::getListas', {
      ini: App.moment(dtIni).format('YYYY-MM-DD'), fim: App.moment(dtFim).format('YYYY-MM-DD'),
    }).then(r => setListas(r.results))
  }

  useEffect(() => getListas(), [dtIni, dtFim])

  function processPortaria(data) {
    let QT_VCL = data.lst_portaria[7].QT_VCL
    data.lst_portaria[7] = (f.groupContrato(data.lst_portaria[7].LST_VCL, ['ID_CN', 'NM_TRP'], 'QT_ORG', 'Encerrado'))
    data.lst_portaria[7].QT_VCL = QT_VCL

    return data
  }

  function get() {
    setLoading(true)
    setData({})
    App.api('fracionada::consulta', {
      ini: App.moment(dtIni).format('YYYY-MM-DD'),
      fim: App.moment(dtFim).format('YYYY-MM-DD'),
      und: filtro?.unidades,
      prc: filtro?.clientes,
      id_pro: filtro?.produtos,
      tp_op: filtro?.operacoes
    }).then(r => {
      const processedData = processPortaria(r.results)
      setData(processedData)
      setTrpGraphData(
        (() => {
          const total = processedData.lst_portaria[1].LST_VCL.reduce((acc, cur) => acc + parseFloat(cur.QT_ORG), 0)
          const trp_sorted = processedData.lst_portaria[1].LST_VCL.sort((a, b) => b.QT_ORG - a.QT_ORG).map(trp => ({
            name: trp.NM_TRP,
            qnt: parseFloat(trp.QT_ORG),
            percent: parseInt(parseFloat(trp.QT_ORG) / total * 10000) / 10000
          }))

          return [...trp_sorted.slice(0, 9), trp_sorted.slice(9).reduce((acc, cur) => ({
            name: 'Outros',
            qnt: parseInt(((acc.qnt ?? 0) + cur.qnt) * 100) / 100,
            percent: parseInt(((acc.qnt ?? 0) + cur.qnt) / total * 10000) / 10000
          }), {})]
        })() ?? []
      )
    }).finally(() => setLoading(null))
  }

  const colunasTable = [
    [ // Cliente 0
      [lang.cliente, 'NM_PRC'],
      [lang.origens, 'ORIGENSLABEL'],
      [lang.agendado, 'QT_AGENDADO'],
      [lang.transito, 'QT_TRANSITO'],
      [lang.recepcionado, 'QT_RECEPCAO'],
      [lang.encerrado, 'QT_ENCERRADO'],
      [lang.no_show, 'QT_NOSHOW'],
      [lang.cancelado, 'QT_CANCELADO'],
      [lang.volume_ton, 'QT_ORG'],
    ],
    [ // Transportadora 1
      [lang.transportadora, 'NM_TRP'],
      [lang.agendado, 'QT_AGENDADO'],
      [lang.transito, 'QT_TRANSITO'],
      [lang.recepcionado, 'QT_RECEPCAO'],
      [lang.encerrado, 'QT_ENCERRADO'],
      [lang.no_show, 'QT_NOSHOW'],
      [lang.cancelado, 'QT_CANCELADO'],
      [lang.volume_ton, 'QT_ORG'],
    ],
    [ // Agendado 3
      ['OC', 'ID_OC'], // ID_UOCC da OC
      [lang.unidade, 'ID_UORG'], // NR_CNPJUND da OCT
      [lang.data_prevista, 'DT_PRV'], // DT_PRV da APR
      [lang.turno, 'DS_TRN'], // ID_TRN da OCT
      [lang.origem, 'DS_ORG'], // ORIGENS
      [lang.destino, 'DS_DST'], // DESTINO
      [lang.transportadora, 'NM_TRP'], // ID_TRP da APR
      [lang.veiculo, 'ID_VCL'], // ID_VCL da APR
      [lang.ticket, 'ID_UOCC'], // ID_UOCC DA APR
      [lang.acao, 'action'] // Deletar portaria
    ],
    [ // Transito 4
      ['OC', 'ID_OC'], // ID_UOCC da OC
      [lang.unidade, 'ID_UORG'], // NR_CNPJUND da OCT
      [lang.data_prevista, 'DT_PRV'], // DT_PRV da APR
      [lang.turno, 'DS_TRN'], // ID_TRN da OC
      [lang.origem, 'DS_ORG'], // ORIGENS
      [lang.destino, 'DS_DST'], // DESTINO
      [lang.transportadora, 'NM_TRP'], // ID_TRP da APR
      [lang.veiculo, 'ID_VCL'], // ID_VCL da APR
      [lang.ticket, 'ID_UOCC'], // ID_UOCC DA APR
      [lang.acao, 'action'] // Deletar portaria
    ],
    [ // Em fila 5
      ['OC', 'ID_OC'], // ID_UOCC da OC
      [lang.unidade, 'ID_UORG'], // NR_CNPJUND da OCT
      [lang.data_prevista, 'DT_PRV'], // DT_PRV da APR
      [lang.turno, 'DS_TRN'], // ID_TRN da OC
      [lang.origem, 'DS_ORG'], // ORIGENS
      [lang.destino, 'DS_DST'], // DESTINO
      [lang.transportadora, 'NM_TRP'], // ID_TRP da APR
      [lang.veiculo, 'ID_VCL'], // ID_VCL da APR
      [lang.ticket, 'ID_UOCC'], // ID_UOCC DA APR
      [lang.acao, 'action'] // Deletar portaria
    ],
    [ // Operação 6
      ['OC', 'ID_OC'], // ID_UOCC da OC
      [lang.unidade, 'ID_UORG'], // NR_CNPJUND da OCT
      [lang.data_prevista, 'DT_PRV'], // DT_PRV da APR
      [lang.turno, 'DS_TRN'], // ID_TRN da OC
      [lang.origem, 'DS_ORG'], // ORIGENS
      [lang.destino, 'DS_DST'], // DESTINO
      [lang.transportadora, 'NM_TRP'], // ID_TRP da APR
      [lang.veiculo, 'ID_VCL'], // ID_VCL da APR
      [lang.ticket, 'ID_UOCC'], // ID_UOCC DA APR
      [lang.acao, 'action'] // Deletar portaria
    ],
    [ // Encerrado 7
      ['OC', 'ID_OC'], // ID_UOCC da OC
      [lang.unidade, 'ID_UORG'], // NR_CNPJUND da OCT
      [lang.data_prevista, 'DT_PRV'], // DT_PRV da APR
      [lang.turno, 'DS_TRN'], // ID_TRN da OC
      [lang.origem, 'DS_ORG'], // ORIGENS
      [lang.destino, 'DS_DST'], // DESTINO
      [lang.transportadora, 'NM_TRP'], // ID_TRP da APR
      [lang.veiculo, 'ID_VCL'], // ID_VCL da APR
      [lang.ticket, 'ID_UOCC'], // ID_UOCC DA APR
      [lang.acao, 'action'] // Deletar portaria
    ],
    [ // Cancelado 8
      ['OC', 'ID_OC'], // ID_UOCC da OC
      [lang.unidade, 'ID_UORG'], // NR_CNPJUND da OCT
      [lang.data_prevista, 'DT_PRV'], // DT_PRV da APR
      [lang.turno, 'DS_TRN'], // ID_TRN da OC
      [lang.origem, 'DS_ORG'], // ORIGENS
      [lang.destino, 'DS_DST'], // DESTINO
      [lang.transportadora, 'NM_TRP'], // ID_TRP da APR
      [lang.veiculo, 'ID_VCL'], // ID_VCL da APR
      [lang.ticket, 'ID_UOCC'], // ID_UOCC DA APR
      [lang.acao, 'action'] // Deletar portaria
    ],
    [ // NoShow 9
      ['OC', 'ID_OC'], // ID_UOCC da OC
      [lang.unidade, 'ID_UORG'], // NR_CNPJUND da OCT
      [lang.data_prevista, 'DT_PRV'], // DT_PRV da APR
      [lang.turno, 'DS_TRN'], // ID_TRN da OC
      [lang.origem, 'DS_ORG'], // ORIGENS
      [lang.destino, 'DS_DST'], // DESTINO
      [lang.transportadora, 'NM_TRP'], // ID_TRP da APR
      [lang.veiculo, 'ID_VCL'], // ID_VCL da APR
      [lang.ticket, 'ID_UOCC'], // ID_UOCC DA APR
      [lang.acao, 'action'] // Deletar portaria
    ],

  ]

  useEffect(() => { // Melhor jeito de lidar com as atualizacoes de linguagem?
    if (data.lst_portaria) {
      var auxData = data
      // Cliente

      data.lst_portaria = data.lst_portaria.map(p => ({
        ...p,
        LST_VCL: p.LST_VCL.map(v => ({
          ...v,
          DS_ORG: v?.ORIGENS?.map(d => <div>{d.NM_PES}</div>),
          DS_DST: v?.DESTINOS?.map(d => <div>{d.NM_PES}</div>),

        }))
      }));

      data.lst_portaria[0].LST_VCL = data.lst_portaria?.[0].LST_VCL.map(v => ({
        ...v,
        ORIGENSLABEL: v.ORIGENS.map(o => <div>{o.NM_PES}</div>),
        cota_cif_fob: v.QT_COTA + ' (' + v.QT_CIF + ' / ' + v.QT_FOB + ')',
        consumida_disponivel: v.QT_COTA + ' / ' + v.QT_DISPONIVEL
      }))

      // Agendado
      data.lst_portaria[3].LST_VCL = data.lst_portaria?.[3].LST_VCL.map(v => ({
        ...v,
        action:
          <button onClick={() => setCancelAgendado({ open: true, id: v.ID_UOCC, vcl: v.ID_VCL, motivo: '' })} title={App.lang.global.cancel}>
            {icons.MdCancel()}
          </button>,
      }))

      // Transito
      data.lst_portaria[4].LST_VCL = data.lst_portaria?.[4].LST_VCL.map(v => ({
        ...v,
        action:
          <button onClick={() => setCancelAgendado({ open: true, id: v.ID_UOCC, vcl: v.ID_VCL, motivo: '' })} title={App.lang.global.cancel}>
            {icons.MdCancel()}
          </button>,
      }))
      setData(auxData)
    }
  }, [data, App.lang])

  const style = { minWidth: 400 }

  return (<>
    <c.Frame className="filter-frame" autoScroll={false} flex style={{ zIndex: 1 }}>
      <c.DaterangePicker label={lang.data} startDate={dtIni} endDate={dtFim} onChange={(ini, fim) => { setDtIni(ini); setDtFim(fim) }} />
      <c.Select value={filtro?.unidades} onChange={v => setFiltro({ ...filtro, unidades: v.map(v => v.value) })} options={listas?.unidades} label={lang.unidades} multi style={style} searchable className='f3' />
      <c.Select value={filtro?.clientes} onChange={v => setFiltro({ ...filtro, clientes: v.map(v => v.value) })} options={listas?.clientes} label={lang.clientes} multi style={style} searchable className='f3' />
      <c.Select isDisabled value={'carga'} options={[{ label: lang.operacoesCarga, value: 'carga' }]} label={lang.operacoes} style={style} searchable className='f3' />
      <button onClick={() => get()} disabled={!!App.loading} className='f1'>{icons.MdSearch({ size: 24 })} {App.lang.global.pesquisar}</button>
    </c.Frame>

    {!loading || <App.LoadingComponent />}

    {!!data.lst_portaria && <c.Frame flex><p.GerencialFracionadaCards data={data} /></c.Frame>}

    {!!data.lst_portaria && <c.Frame>
      <c.Tabs>
        <c.TabList>
          {data.lst_portaria?.map((t, ti) =>
            <c.Tab key={ti}>{App.f.capitalize(t.DS_STS)}</c.Tab>
          )}
        </c.TabList>

        {data.lst_portaria?.map((t, i) => !!t && !!colunasTable[i] &&
          <c.TabPanel key={i}>
            <div className="f f-column g1 w100">

              <c.Table data={t.LST_VCL}
                columns={colunasTable[i]}
              />
            </div>
          </c.TabPanel>
        )}
      </c.Tabs>
    </c.Frame>}

    {/* Modais */}
    {cancelAgendado.open &&
      <c.Modal title={lang.cancelar_veiculo} onClose={() => setCancelAgendado({})}
        onFinish={() =>
          App.api('portarias::alteraSituacao', { id_uocc: cancelAgendado.id, cd_sts: -3, ds_mtv: cancelAgendado.motivo })
            .then(r => { if (r.status) { setCancelAgendado({}); get(); return r.status } })
        }
        validate={cancelAgendado.motivo.length >= 10}
      >
        <c.Input value={cancelAgendado.motivo} minLength="10"
          label={lang.cancelar_veiculo_informe_motivo + cancelAgendado.vcl + lang.cancelar_veiculo_de_ticket + cancelAgendado.id}
          onChange={(e) => setCancelAgendado({ ...cancelAgendado, motivo: e.target.value })} />
      </c.Modal>
    }
  </>)
}
