import { ColumnsCache } from '../models/ColumnsCache'

const waitForStatus = (tableId, timeout = 5000, interval = 100) => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();
    const checkStatus = () => {
      const [_, status] = ColumnsCache.getData(tableId);
      if (status === 'loaded' || status === 'expired') {
        resolve();
      } else if (Date.now() - startTime > timeout) {
        reject(new Error('Timeout waiting for status to change'));
      } else {
        setTimeout(checkStatus, interval);
      }
    };
    checkStatus();
  });
};

export const fetchColumns = async ({ api, tableId }) => {
  let [cachedData, status] = ColumnsCache.getData(tableId);

  if (status === 'loading') {
    try {
      await waitForStatus(tableId);
      [, status] = ColumnsCache.getData(tableId);
      if (status === 'loaded') return cachedData;
      // Optionally handle 'expired' status
    } catch (error) {
      return cachedData;
    }
  }

  ColumnsCache.setData(tableId, [], 'loading');

  let { results: prefs = [] } = await api("users::getColunas", { ID_TABLE: tableId });

  if (JSON.stringify(prefs) === '{}') prefs = []

  ColumnsCache.setData(tableId, prefs, 'loaded');

  return prefs;
};
